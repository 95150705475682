<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar0";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Page-privacy component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Политика в отношении обработки персональных данных </h4>
                        <ul class="list-unstyled mt-4">
                            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Дата публикации :</span> 9 Марта, 2021</li>
                        </ul>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Skillrank</router-link>
                                    </li>
                                    <li class="breadcrumb-item"><a href="#">Документы</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Политика конфиденциальности</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Privacy -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow rounded border-0">
                        <div class="card-body">

<h4 class="title"> Политика в отношении обработки персональных данных </h4>

<h5 class="card-title">1. Общие положения </h5>
<p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые администратором сайта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>, Индивидуальным предпринимателем "Гончаренко Игорь Сергеевич" (далее – Оператор или Администратор Сайта).</p>

<p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
<p>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>.</p>

<h5 class="card-title">2. Основные понятия, используемые в Политике</h5>
<p>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</p>
<p>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
<p>2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>;</p>
<p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;</p>
<p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
<p>2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
<p>2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</p>
<p>2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>;</p>
<p>2.9. Пользователь – любой посетитель веб-сайта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>;</p>
<p>2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
<p>2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
<p>2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</p>
<p>2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</p>

<h5 class="card-title">3. Оператор может обрабатывать следующие персональные данные Пользователя</h5>
<ul class="list-unstyled">
<li> 3.1. Фамилия, имя, отчество;</li>
<li>3.2. Электронный адрес;</li>
<li>3.3. Номера телефонов;</li>
<li>3.4. Год, месяц, дата и место рождения;</li>
<li>3.5. Фотографии;</li>
<li>3.6. Сведения об образовании, профессии, специальности и квалификации, реквизиты документов об образовании;</li>
  <li>3.7. Сокращенную версию учетных данных Пользователя (например, последние четыре цифры номера карты)  </li>
<li>3.8. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</li>
<li>3.9. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</li>
</ul>
<h5 class="card-title">4. Цели обработки персональных данных</h5>
<p>4.1. Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте.</p>
<p>4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях, различных событиях и любых изменениях в платежном соглашении. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:igor_goncharenko@skillrank.ru" class="text-primary">igor_goncharenko@skillrank.ru</a> с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».</p>
<p>4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
<p>4.4. Сокращенная версия учетных данных Пользователя служит для оформления регулярных и безакцептных платежей с карты Пользователя на основе выбранного Пользователем тарифа. </p>

<h5 class="card-title">5. Правовые основания обработки персональных данных</h5>
<p>5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
<p>5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).</p>

<h5 class="card-title">6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h5>
<p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
<p>6.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
<p>6.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.</p>
<p>6.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора <a href="mailto:igor_goncharenko@skillrank.ru" class="text-primary">igor_goncharenko@skillrank.ru</a> с пометкой «Актуализация персональных данных».</p>
<p>6.4. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="mailto:igor_goncharenko@skillrank.ru" class="text-primary">igor_goncharenko@skillrank.ru</a> с пометкой «Отзыв согласия на обработку персональных данных».</p>

<h5 class="card-title">7. Трансграничная передача персональных данных</h5>
<p>7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</p>
<p>7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.</p>

<h5 class="card-title">8. Заключительные положения</h5>
<p>8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a href="mailto:igor_goncharenko@skillrank.ru" class="text-primary">igor_goncharenko@skillrank.ru</a>.</p>
<p>8.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
<p>8.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://skillrank.ru/privacy" class="text-primary">https://skillrank.ru/privacy</a>.</p>

<h5 class="card-title">9. Реквизиты Администрации Сайта</h5>
<ul class="list-unstyled">
  <li>Индивидуальный предприниматель "Гончаренко Игорь Сергеевич"</li>
  <li>Адрес: 140073, Московская Область, п.Томилино, мкр Птицефабрика, дом 34</li>
  <li>ИНН: 667327225183</li>
  <li>ОГРНИП: 321508100088630</li>
  <!--
  <li> Банковские реквизиты:</li>
  <li>Расчётный счёт: 40802810838000241200</li>
  <li>БИК: 044525225</li>
  <li> Банк: ПАО СБЕРБАНК</li>
  <li> Корр. счёт: 30101810400000000225</li>
  <br>
  <li> Тел: +7(909)163-5804</li>
  -->
  <li> Email: <a href="mailto:igor_goncharenko@skillrank.ru" class="text-primary">igor_goncharenko@skillrank.ru</a></li>
  <li> Telegram: IgorSkillrank</li>
</ul>

                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Privacy -->
    <!--end section-->
    <Footer />
    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
